import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import VerificationPage from './pages/VerificationPage';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import SuperUserMenu from './components/SuperUserMenu';
import PartnerAccessControl from './components/PartnerAccessControl';
import CreateBusinessGroup from './components/CreateBusinessGroup';
import CreateAdminUser from './components/CreateAdminUser';
import DashboardFiltered from './pages/DashboardFiltered';
import ResetPasswordPage from './pages/ResetPassword/ResetPasswordPage'; // Novo import

function App() {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const toggleMenu = () => {
    setIsMenuCollapsed((prev) => !prev);
  };

  return (
    <Router>
      <Routes>
        {/* Rotas públicas */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/verify" element={<VerificationPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} /> {/* Nova rota */}

        {/* Rotas protegidas */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <div className="flex min-h-screen">
                <SuperUserMenu
                  isCollapsed={isMenuCollapsed}
                  toggleMenu={toggleMenu}
                />
                <div
                  className={`flex-1 p-6 transition-all ${
                    isMenuCollapsed ? 'ml-16' : 'ml-64'
                  }`}
                >
                  <Dashboard coligadaId={0} /> {/* Dashboard geral */}
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/ett"
          element={
            <PrivateRoute>
              <div className="flex min-h-screen">
                <SuperUserMenu
                  isCollapsed={isMenuCollapsed}
                  toggleMenu={toggleMenu}
                />
                <div
                  className={`flex-1 p-6 transition-all ${
                    isMenuCollapsed ? 'ml-16' : 'ml-64'
                  }`}
                >
                  <Dashboard coligadaId={1} /> {/* Dashboard para Clientes ETT */}
                </div>
              </div>
            </PrivateRoute>
          }
        />

        {/* Rota restrita ao DashboardFiltered */}
        <Route
          path="/dashboard-filtered"
          element={
            <PrivateRoute>
              <DashboardFiltered />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard/first"
          element={
            <PrivateRoute>
              <div className="flex min-h-screen">
                <SuperUserMenu
                  isCollapsed={isMenuCollapsed}
                  toggleMenu={toggleMenu}
                />
                <div
                  className={`flex-1 p-6 transition-all ${
                    isMenuCollapsed ? 'ml-16' : 'ml-64'
                  }`}
                >
                  <Dashboard coligadaId={6} /> {/* Dashboard para Clientes FIRST */}
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/config/partner-access"
          element={
            <PrivateRoute>
              <div className="flex min-h-screen">
                <SuperUserMenu
                  isCollapsed={isMenuCollapsed}
                  toggleMenu={toggleMenu}
                />
                <div
                  className={`flex-1 p-6 transition-all ${
                    isMenuCollapsed ? 'ml-16' : 'ml-64'
                  }`}
                >
                  <PartnerAccessControl />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/config/create-business-group"
          element={
            <PrivateRoute>
              <div className="flex min-h-screen">
                <SuperUserMenu
                  isCollapsed={isMenuCollapsed}
                  toggleMenu={toggleMenu}
                />
                <div
                  className={`flex-1 p-6 transition-all ${
                    isMenuCollapsed ? 'ml-16' : 'ml-64'
                  }`}
                >
                  <CreateBusinessGroup />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/config/create-admin-user"
          element={
            <PrivateRoute>
              <div className="flex min-h-screen">
                <SuperUserMenu
                  isCollapsed={isMenuCollapsed}
                  toggleMenu={toggleMenu}
                />
                <div
                  className={`flex-1 p-6 transition-all ${
                    isMenuCollapsed ? 'ml-16' : 'ml-64'
                  }`}
                >
                  <CreateAdminUser grupoEmpresarialId={1} /> {/* Exemplo com ID fixo, pode ser dinâmico */}
                </div>
              </div>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
