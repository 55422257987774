import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import portalBackground from '../../images/portal_fundo.png'; // Corrigido o caminho da imagem

const ResetPasswordPage: React.FC = () => {
  const { token } = useParams<{ token: string }>(); // Captura o token da URL
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setMessage('As senhas não coincidem.');
      setLoading(false);
      return;
    }

    try {
     // await axios.post('https://apicliente.firstrhgroup.com/api/reset-password', {
      await axios.post('https://apicliente.firstrhgroup.com/api/reset-password', {
        token, // Token da URL
        password, // Nova senha
        password_confirmation: confirmPassword, // Confirmação
      });

      setMessage('Senha redefinida com sucesso! Redirecionando para o login...');
      setTimeout(() => {
        navigate('/'); // Redireciona para a tela de login (LoginPage.tsx)
      }, 3000);
    } catch (error: any) {
      console.error('Erro ao redefinir senha:', error);
      setMessage(
        error.response?.data?.message || 'Erro ao redefinir senha. Tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex items-center justify-center h-screen bg-gray-100"
      style={{
        backgroundImage: `url(${portalBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <form onSubmit={handlePasswordReset} className="bg-white p-6 rounded shadow-md w-80">
        <h2 className="text-lg font-bold mb-4 text-center">Redefinir Senha</h2>
        {message && <p className="text-sm mb-4 text-center text-red-500">{message}</p>}
        <input
          type="password"
          placeholder="Nova senha"
          className="border p-2 mb-4 w-full"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirme a nova senha"
          className="border p-2 mb-4 w-full"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 w-full rounded"
          disabled={loading}
        >
          {loading ? 'Carregando...' : 'Redefinir Senha'}
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
